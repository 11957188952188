var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-0 py-0" },
    [
      _vm._l(_vm.subSections, function(ref, index) {
        var title = ref.title
        var component = ref.component
        var slug = ref.slug
        var enabled = ref.enabled
        return [
          _c(
            "v-slide-y-reverse-transition",
            { key: index },
            [
              enabled
                ? _c("form-section", {
                    attrs: { sectionTitle: title },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "col-1",
                          fn: function() {
                            return [
                              _c(component, {
                                tag: "component",
                                attrs: {
                                  application: _vm.application,
                                  applicant: _vm.applicant
                                },
                                on: {
                                  update: function($event) {
                                    return _vm.updateSubSection(slug, $event)
                                  }
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ]
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { "offset-md": "2" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "my-2",
                  attrs: { color: "secondary", disabled: _vm.nextStepDisabled },
                  on: { click: _vm.nextStep }
                },
                [
                  _vm._v(_vm._s(_vm.$t("applications.continue")) + " "),
                  _c(
                    "v-icon",
                    { staticClass: "ml-1 secondary--text text--lighten-2" },
                    [_vm._v("mdi-chevron-right-circle")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="my-0 py-0">
        <template
            v-for="({ title, component, slug, enabled }, index) in subSections"
        >
            <v-slide-y-reverse-transition :key="index">
                <form-section :sectionTitle="title" v-if="enabled">
                    <template v-slot:col-1>
                        <component
                            :is="component"
                            :application="application"
                            :applicant="applicant"
                            @update="updateSubSection(slug, $event)"
                        >
                        </component>
                    </template>
                </form-section>
            </v-slide-y-reverse-transition>
        </template>
        <v-row>
            <v-col offset-md="2">
                <v-btn
                    color="secondary"
                    class="my-2"
                    @click="nextStep"
                    :disabled="nextStepDisabled"
                    >{{ $t("applications.continue") }}
                    <v-icon class="ml-1 secondary--text text--lighten-2"
                        >mdi-chevron-right-circle</v-icon
                    >
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    name: "application-setup",
    components: {
        PropertyDetails: () => import("./PropertyDetails"),
        PropertyMeta: () => import("./PropertyMeta"),
        ApplicantType: () => import("./ApplicantType"),
        ApplicantInfo: () => import("./ApplicantInfo"),
        CosignerAlert: () => import("./CosignerAlert"),
        VerifyScreening: () => import("./VerifyScreening"),
        RentalCriteria: () => import("./RentalCriteria"),
        MobilityRentalHistory: () => import("./MobilityRentalHistory"),
        EmploymentInfo: () => import("./EmploymentInfo"),
        UploadFiles: () => import("./UploadFiles"),
    },
    props: {
        step: {
            type: [Number, String],
            required: true,
        },
        applicant: {
            type: Object,
            required: true,
        },
        application: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            subSections: this.buildSubSections(),
        };
    },
    computed: {
        /**
         * Disables next step until all sub sections are complete
         *
         * @return void
         */
        nextStepDisabled() {
            return !this.subSections.every((section) => section.complete);
        },
    },

    methods: {
        /**
         * Increments the next step in the stepper sequence
         *
         * @event next - incremented input step
         *
         * @return void
         */
        nextStep() {
            this.$emit("next", parseInt(this.step) + 1);
        },
        /**
         * Builds the initial set of setup sub sections from the input section data
         *
         * @return void
         */
        buildSubSections() {
            return this.$config("applications.setupSubSections")
                .filter((section) => {
                    return this.$_.get(
                        this.applicant,
                        `sections.${section.slug}`,
                        false
                    );
                })
                .map((section) => {
                    section.title = this.$t(`applications.${section.slug}`);
                    return section;
                });
        },
        /**
         * Completes the subsection defined by the input slug
         *
         * @param {any} slug
         * @return void
         */
        completeSubSection(slug) {
            this.setSubSection(slug, "complete", true);
            // Since we completed the subsection, move on to the next
            this.enableNextSubSection(slug);
        },
        /**
         * Enables next section in the subsection array that is not complete
         *
         * @param {any} slug
         * @return void
         */
        enableNextSubSection(slug) {
            // current subsection
            const index = this.subSections.findIndex(
                (section) => section.slug === slug
            );
            // we have more subsections to enable
            if (index !== this.subSections.length - 1) {
                this.setSubSection(
                    this.subSections[index + 1].slug,
                    "enabled",
                    true
                );
            }
        },
        /**
         * Sets a key/value pair on the subsection determined by the input slug
         *
         * @param {string} slug
         * @param {string} key
         * @param {any} value
         * @return void
         */
        setSubSection(slug, key, value) {
            const index = this.subSections.findIndex(
                (section) => section.slug === slug
            );
            let subSection = this.subSections[index];
            subSection[key] = value;
            this.subSections.splice(index, 1, subSection);
        },
        /**
         * Handler for update callback from setup components
         *
         * @param {string} slug
         * @param {any} data
         * @return void
         */
        updateSubSection(slug, data) {
            // did we complete the subsection
            if (this.$_.get(data, "complete", false)) {
                this.completeSubSection(slug);
            }
            if (this.$_.has(data, "applicant")) {
                // we are updating the applicant
                this.$emit("update:applicant", data["applicant"]);
            }
        },
    },
};
</script>

<style scoped>
</style>

